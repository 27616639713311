import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Flex
     
    
  } from '@chakra-ui/react';
  import axios from 'axios';
  import { increment } from '../redux/Slices/CheckSlice';
  import { useDispatch } from 'react-redux';
  import toast,{Toaster} from 'react-hot-toast'
import { useState } from 'react';
import { Form, Formik } from 'formik';
function CheckEntryModal({id,patient}) {
  const [newCheck,setNewCheck] = useState('')
  const [dateOfNewCheck,setDateOfNewCheck] = useState([]);
  const [newPhysicalVisit,setNewPhysicalVisit] = useState('');
  const [newExaminations,setNewExaminations] = useState('');
  const [newDiagnostic,setNewDiagnostic] = useState('');
  const [newTreatment,setNewTreatment] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();

   
    const createNewCheck = async(patient) => {
      await axios.put(`https://pacienti-app-api.vercel.app/api/createNewCheck/${id}`, {
        
          check:[newCheck],
          physicalVisit: [newPhysicalVisit],
          examinations: [newExaminations],
          diagnostic: [newDiagnostic],
          treatment: [newTreatment],
              DateOfCheck:[dateOfNewCheck]
          
        
      }
     ).then((res) => console.log(res)).catch((err) => console.log(err))
  
     }
    return (
      <>
      <Toaster/>
        <Button color={'#18143A'} fontWeight={'700'} onClick={onOpen}>Shto +</Button>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>

            <ModalHeader>Vizitë e re</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                          <Formik
            initialValues={
    {
      name: "",
      surname: '',
      dateOfCheck: [],
      reasonOfCheck: '',
      physicalVisit: '',
      examinations: '',
      diagnostic: '',
      treatment: '',
      
    }
  }
  onSubmit={(values,actions) => {
createNewCheck(id)
onClose()

toast.success("Kontrolla e re u shtua me sukses!")
setInterval(() => {
 actions.setSubmitting(false)

},500)
  }}
            >

            {({values,isSubmitting,handleSubmit,errors}) => (
              <Form onSubmit={handleSubmit}>
<FormControl isRequired mt={4}>
                <FormLabel>Data e Vizitës</FormLabel>
                <Input required type={'date'}   onChange={(e) => setDateOfNewCheck(e.target.value)} />
              </FormControl>
            <FormControl isRequired mt={4}>
                <FormLabel>Anamneza</FormLabel>
                <Textarea placeholder='Problemi' onChange={(e) => setNewCheck(e.target.value)} />
              </FormControl>
              <FormControl  mt={4}>
                <FormLabel>Vizita Fizike(opcionale)</FormLabel>
                <Textarea placeholder='' onChange={(e) => setNewPhysicalVisit(e.target.value)} />
              </FormControl>
              <FormControl  mt={4}>
                <FormLabel>Ekzaminimet(opcionale)</FormLabel>
                <Textarea placeholder='' onChange={(e) => setNewExaminations(e.target.value)} />
              </FormControl>
              <FormControl isRequired mt={4}>
                <FormLabel>Diagnoza</FormLabel>
                <Textarea placeholder='' onChange={(e) => setNewDiagnostic(e.target.value)} />
              </FormControl>
              <FormControl isRequired mt={4}>
                <FormLabel>Trajtimi / Terapia</FormLabel>
                <Textarea placeholder='' onChange={(e) => setNewTreatment(e.target.value)} />
              </FormControl>
               <Flex justifyContent={'end'} mb={4} mt={8}>
              <Button mr={3} type='submit' colorScheme='blue' isLoading={isSubmitting}>Shto kontrollen</Button>
              <Button    onClick={onClose}>
                Anulo
              </Button>
               </Flex>
              </Form>
              
            )}
              </Formik>
            </ModalBody>
  
            
           
          </ModalContent>
        </Modal>
      </>
  )
  
}

export default CheckEntryModal