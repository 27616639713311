import React from 'react';
import {Route,Routes} from 'react-router-dom'
import Navbar from './components/Navbar'
import ProtectedRoutes from './components/ProtectedRoutes';
import PublicRoute from './components/PublicRoute';
import HomeScreen from './pages/HomeScreen';
import Login from './pages/Login';
import Register from './pages/Register.tsx'
import DoctorProfileEdit from './components/ProfileEditModal';
import ForgotPassword from './components/ForgotPassword';
import PasswordResetF from './components/PasswordResetF';
import TokenError from './components/TokenError';
import PrintPage from './components/PrintPage';
import Statistics from './pages/Statistics';
import Dashboard from './pages/Dashboard';
import SubscriptionPage from './pages/SubscriptionPage';
import Admin from './pages/Admin';
import MailBox from './components/MailBox';
import EmailConfirmationPage from './pages/EmailConfirmationPage';
import SuccessPage from './components/SuccessPage';
function App() {
 
  return (
  
 <Routes>
  <Route path='/' element={<Navbar/>}>
   
  <Route index element={<ProtectedRoutes><Dashboard/></ProtectedRoutes>}></Route>
  <Route path='/patients' element={<ProtectedRoutes><HomeScreen/></ProtectedRoutes>}>
    </Route>
   
  <Route
          path="/doctor/profile/:userId"
          element={
            <ProtectedRoutes>
              <DoctorProfileEdit/>
            </ProtectedRoutes>
          }
        />
<Route path='/statistics' element={<ProtectedRoutes><Statistics/></ProtectedRoutes>}></Route>
<Route path='/subscription' element={<ProtectedRoutes><SubscriptionPage/></ProtectedRoutes>}/>
  </Route>
  
   <Route path='/login' element={<PublicRoute><Login/></PublicRoute>}></Route>
   <Route path='/forgot-password' element={<PublicRoute><ForgotPassword></ForgotPassword></PublicRoute>}></Route>
  <Route path='/register' element={<PublicRoute><Register/></PublicRoute>}></Route>
  <Route path='/reset-password/:id/:token' element={<PublicRoute><PasswordResetF/></PublicRoute>} />
<Route path='/error-token' element={<TokenError/>}></Route>
<Route path='/print' element={<ProtectedRoutes><PrintPage/></ProtectedRoutes>}></Route>
<Route path='/admin' element={<ProtectedRoutes><Admin/></ProtectedRoutes>}></Route>
<Route path='/mail' element={<ProtectedRoutes><MailBox/></ProtectedRoutes>}></Route>
<Route path='/emailConfirmation*291029dnsjsbfybs/250898' element={<PublicRoute><EmailConfirmationPage/></PublicRoute>}></Route>
<Route path='/success' element={<PublicRoute><SuccessPage/></PublicRoute>}></Route>
 </Routes>
 
  );
}

export default App;

