import React  from 'react'
import moment from 'moment';
import HistoryModal from './HistoryModal';
import Loader from './Loader';
import { Tbody, Td,Tr } from '@chakra-ui/react';
import CheckEntryModal from './CheckEntryModal';
import DeletePatient from './DeletePatient';
function PatientsDashboard({listOfPatients,loader, searchTerm}) {
  return (
    <Tbody>
              {loader ? listOfPatients
                .filter((patient) => {
                  if (searchTerm === "") {
                    return patient;
                  } else if (
                    patient.name
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase()) ||
                    patient.surname
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
                  ) {
                    return patient;
                  }
                  return null
                })
                .map((patient) => {
                  const lastUpdated = patient.updatedAt;
                  const formattedData = moment(lastUpdated)
                    .utc()
                    .format("DD/MM/YY");

                  return (
                    <Tr key={patient._id}>
                      <Td>{patient.name}</Td>
                      <Td>{patient.surname}</Td>
                      <Td className="text-left" isNumeric>
                        {formattedData}
                      </Td>
                      <Td className="text-center" isNumeric>
                        <CheckEntryModal id={patient._id} patient={patient} />
                      </Td>
                      <Td isNumeric>
                        <HistoryModal
                          id={patient?._id}
                          patientBirthday={patient?.birthday}
                          patientName={patient?.name}
                          patientSurname={patient?.surname}
                          patientCheck={patient?.PatientCheck}
                          patientDateCheck={patient?.PatientCheck.Date}
                        />
                      </Td>

                      <Td isNumeric>
                        <DeletePatient patientId={patient._id} />
                      </Td>
                    </Tr>
                  );
                }) : <Loader/>}
            </Tbody>
  )
}

export default PatientsDashboard