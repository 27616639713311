import React, { useState } from "react";
import { Formik } from "formik";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import axios from "axios";
import { useSelector } from "react-redux";

export function AddButton(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user } = useSelector((state) => state.user);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dateOfCheck, setDateOfCheck] = useState([]);
  const [reasonOfCheck, setReasonOfCheck] = useState("");
  const [physicalVisit, setPhysicalVisit] = useState("");
  const [examinations, setExaminations] = useState("");
  const [diagnostic,setDiagnostic] = useState('');
  const [treatment,setTreatment] = useState('');
  const [birthday,setBirthday] = useState('');
  // const [documentOfCheck,setDocumentOfCheck] = useState({});
  
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const createPatient = async () => {
    await axios
      .post("https://pacienti-app-api.vercel.app/api/createPatient", {
        name: name,
        surname: surname,
        birthday: birthday,
        PatientCheck: [{ check: [reasonOfCheck],
           physicalVisit:[physicalVisit],
           examinations: [examinations],
           diagnostic: [diagnostic],
           treatment:[treatment],
            DateOfCheck: [dateOfCheck] }],
        doctorId: user._id,
      })
      .then((response) => {});
  };
  return (
    <>
      <Button color={"#18143A"} bg={"white"} onClick={onOpen}>
        + Shto pacientë
      </Button>

      <Toaster />

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Shto pacientë</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                name: "",
                surname: "",
                birthday: [],
                dateOfCheck: [],
                reasonOfCheck: "",
              }}
              onSubmit={(values, actions) => {
                createPatient();
                toast.success("Pacienti u Shtua Me Sukses!");
                setInterval(() => {
                  actions.setSubmitting(false);
                  
                }, 500);
              }}
            >
              {({ values, isSubmitting, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl isInvalid={errors.name} isRequired>
                    <FormLabel>Emri</FormLabel>
                    <Input
                      onChange={(e) => setName(e.target.value)}
                      id="name"
                      name="name"
                      placeholder="Filan"
                      validate={(value) => {
                        if (value === "") {
                          return "Fushe e detyruar";
                        }
                      }}
                    ></Input>
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl pt={4} isInvalid={errors.surname} isRequired>
                    <FormLabel>Mbiemri</FormLabel>
                    <Input
                      onChange={(e) => setSurname(e.target.value)}
                      id="surname"
                      name="surname"
                      placeholder="Fisteku"
                      validate={(value) => {
                        if (value === "") {
                          return "Fushe e detyruar";
                        }
                      }}
                    ></Input>
                    <FormErrorMessage>{errors.surname}</FormErrorMessage>
                  </FormControl>
                  <FormControl pt={4} isInvalid={errors.date} isRequired>
                    
                    <FormLabel>Datëlindja</FormLabel>
                    <Input
                      onChange={(e) => setBirthday(e.target.value)}
                      id="birthday"
                      name="birthday"
                      type={"date"}
                      
                      validate={(value) => {
                        if (value === "") {
                          return "Fushe e detyruar";
                        }
                      }}
                    ></Input>
                    <FormErrorMessage>{errors.date}</FormErrorMessage>
                  </FormControl>
                  <FormControl pt={4} isInvalid={errors.date} isRequired>

                    <FormLabel>Data e Vizitës</FormLabel>
                    <Input
                      onChange={(e) => setDateOfCheck(e.target.value)}
                      id="date"
                      name="date"
                      type={"date"}
                      placeholder="Fisteku"
                      validate={(value) => {
                        if (value === "") {
                          return "Fushe e detyruar";
                        }
                      }}
                    ></Input>
                    <FormErrorMessage>{errors.date}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isRequired
                    mt={4}
                  >
                    <FormLabel>Anamneza</FormLabel>
                    <Textarea
                      onChange={(e) => setReasonOfCheck(e.target.value)}
                      required
                      placeholder="Problemi"
                      name="reasonOfCheck"
                      id="reasonOfCheck"
                    />
                   
                  </FormControl>
                  <FormControl
                    
                    mt={4}
                  >
                    <FormLabel>Vizita Fizike(opcionale)</FormLabel>
                    <Textarea
                      onChange={(e) => setPhysicalVisit(e.target.value)}
                      
                      placeholder=""
                     
                    />
                   
                  </FormControl>
                  <FormControl
                   
                    mt={4}
                  >
                    <FormLabel>Egzaminimet(opcionale)</FormLabel>
                    <Textarea
                      onChange={(e) => setExaminations(e.target.value)}
                      
                      placeholder=""
               
                    />
                   
                  </FormControl>
                  <FormControl
                    isRequired
                    mt={4}
                  >
                    <FormLabel>Diagnoza</FormLabel>
                    <Textarea
                      onChange={(e) => setDiagnostic(e.target.value)}
                      required
                      placeholder=""
                    
                    />
                   
                  </FormControl>
                  <FormControl
                    isRequired
                    mt={4}
                  >
                    <FormLabel>Trajtimi / Terapia</FormLabel>
                    <Textarea
                      onChange={(e) => setTreatment(e.target.value)}
                      required
                      placeholder=""
                     
                    />
                   
                  </FormControl>
                  <Flex justifyContent={"end"} mt={10}>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      colorScheme="blue"
                      mr={3}
                      onClick={onClose}
                    >
                      Ruaj
                    </Button>
                    <Button onClick={onClose}>Anulo</Button>
                  </Flex>
                </form>
              )}
            </Formik>

            {/* <FormControl mt={4}>
                <FormLabel>Dokumenti kontrolles</FormLabel>
                <Input type={'file'}  onChange={(e) => setDocumentOfCheck(e.target.value)}/>
              </FormControl> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
