import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    Text,
    Button,
    useDisclosure,

  } from '@chakra-ui/react';
import { useEffect } from 'react';
import CheckDetails from './CheckDetails';


function PayModal({id,patient,patientCheck,patientDateCheck,patientName,patientSurname, patientBirthday}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
  const [checkInfo,setCheckInfo] = useState([]);
   const [scrollBehavior, setScrollBehavior] = React.useState('inside')
useEffect(() => {
  setCheckInfo(patientCheck)
 
},[patientCheck])

    return (
      <>
      <div class="mt-6 rounded-md shadow">
            <button onClick={onOpen} class="flex w-full items-center justify-center px-5 py-3 text-base font-bold leading-6 text-white transition duration-150 ease-in-out bg-[#39B6FF] border border-transparent rounded-md  focus:outline-none focus:shadow-outline">
                Paguaj
            </button>
        </div>
       
        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} scrollBehavior={scrollBehavior}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Paguaj planin e abonimit</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
        <Text> Të lutem për pagesë së pari kontaktoni numrin: <br/> <Text align={'center'} pt={4} fontSize={'xl'} color={'#39B6FF'}><b>+38345309362</b></Text> <br/><br/> Për detaje të mëtutjeshme: <br/>  <b>contact@pacientii.com</b></Text>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Mbyll
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default PayModal
