
import {
    Button,
    FormControl,
    Flex,
    Heading,
    Stack,
    useColorModeValue,
    Link,
    Image,
    Input,
    FormLabel,
  } from '@chakra-ui/react';
import axios from 'axios';
  import pacientiLogo from '../assets/logoLogin.png'
  import {Formik, Form } from 'formik';
import { useState} from 'react';

import { Toaster, toast } from 'react-hot-toast';
import {useNavigate, useParams} from 'react-router-dom'

 const PasswordResetF = () => {
    const [newPassword,setNewPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const { id, token } = useParams();
const navigate = useNavigate();
   
    const onSubmit = async () => {
    try{
      const response = await axios.post(`https://pacienti-app-api.vercel.app/api/user/reset-password/${id}/${token}`, {
        newPassword
     });

     if (response.status === 200) {
         toast.success(response.data.message)
       setInterval(() => {
     
navigate('/login')
window.location.reload();
       },700)
        
     } else {
         toast.error("Token i skaduar. Gjeneroni një lidhje të re")
         setInterval(() => {
navigate('/error-token')
         },500)
     }
    }
 catch(error) {
 toast.error("Token i skaduar")
 setInterval(() => {
navigate('/login')
 },200)
}
    }
       
 
   
    return (
  
            <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            flexDirection={'column'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
                <Toaster/>
           <Link  w={'170px'}><Image   src={pacientiLogo}  /></Link>
            <Stack
              spacing={4}
              w={'full'}
              maxW={'md'}
              bg={useColorModeValue('white', 'gray.700')}
              rounded={'xl'}
              boxShadow={'lg'}
              p={6}
              my={12}>
                
              <Heading pb={2} lineHeight={1.1} fontSize={{ base: '2xl', md: '2xl', lg: '3xl' }}>
              Vendosni një fjalëkalim të ri
              </Heading>
              
              <Formik
              initialValues={{
                newPassword: '',
                confirmPassword:''
              }}
              onSubmit={onSubmit}
              >
               {({handleSubmit,isSubmitting,}) => (
                <Form onSubmit={handleSubmit}>
     <FormControl isRequired name='password' id="email" >
        <FormLabel>Fjalekalimi i ri</FormLabel>
                <Input
                  placeholder=""
                  _placeholder={{ color: 'gray.500' }}
                  type="password"
                  name='password'
                  id='password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </FormControl>
              <FormControl pt={4} isRequired name='confirmPassword' id="email" >
        <FormLabel>Rivendosni fjalekalimin e ri</FormLabel>
                <Input
                  placeholder=""
                  _placeholder={{ color: 'gray.500' }}
                  type="password"
                  name='confirmPasword'
                  id='confirmPassword'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormControl>
              <Stack spacing={6}>
                <Button
                isLoading={isSubmitting}
                mt={4}
                type='submit'
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Rivendos fjalëkalimin
                </Button>
                
              </Stack>
                </Form>
               )}
              
              </Formik>
            </Stack>
          </Flex>
          
    
   
    );
  }

  export default PasswordResetF