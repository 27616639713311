import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Flex,
  } from '@chakra-ui/react';

  import axios from 'axios';
  import toast,{Toaster} from 'react-hot-toast'
import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import {InputControl,SubmitButton} from "formik-chakra-ui";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
function ChangePassword({id,patient}) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword,setNewPassword] = useState('');
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user } = useSelector((state) => state.user);
   
    const initialValues = {
    currentPassword: "",
    newPassword: ""
    
    };
    const validationSchema = Yup.object({
      currentPassword: Yup.string().required('Fushë e detyruar'),
      newPassword: Yup.string().required('Fushë e detyruar').min(8, 'Duhet të jetë së paku 8 karaktere.'),
    });


    return (
      <>
      <Toaster/>
        <Button  mt={7} onClick={onOpen}>Ndrysho fjalëkalimin</Button>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>

            <ModalHeader>Ndrysho fjalëkalimin</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                          <Formik
            initialValues={initialValues}
  validationSchema={validationSchema}
  onSubmit={async(values) => {
    try{
      const userId = user?._id;
      const response = await axios.post('https://pacienti-app-api.vercel.app/api/user/update-password',{
        userId,
        currentPassword,
        newPassword
      });
      console.log(values)
      toast.success(response.data.message)
      setInterval(() => {
window.location.reload();
navigate('/')
      },500)
      }catch(error){
        console.log(error);
     toast.error(error.response.data.message)
     console.log(values)
      }
      
  }}
            >

            {({isSubmitting,handleSubmit}) => (
              <Form onSubmit={handleSubmit}>
 <InputControl
         
          isRequired
            name="currentPassword"
            inputProps={{ type: "password" }}
            label="Fjalëkalimi aktual"
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
             

             <InputControl
         pt={4}
         isRequired
           name="newPassword"
           inputProps={{ type:'password'}}
           label="Fjalëkalimi i ri"
           onChange={(e) => setNewPassword(e.target.value)}
       />
    
              
              
             
               <Flex justifyContent={'end'} mb={4} mt={8}>
               <Button  mr={3}   onClick={onClose}>
                Anulo
              </Button>
              <SubmitButton  type='submit' colorScheme={'blue'} background={'#3EB8FF'}  color='white' isLoading={isSubmitting}>Ndrysho</SubmitButton>

               </Flex>
              </Form>
              
            )}
              </Formik>
            </ModalBody>
  
            
           
          </ModalContent>
        </Modal>
      </>
  )
  
}

export default ChangePassword