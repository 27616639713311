import React, { useState } from 'react'
import {
    Box,
    Heading,
Text,
Flex
  } from '@chakra-ui/react';
import { useEffect } from 'react';

import CheckModal from './CheckModal';


function CheckDetails({diagnostic,treatment,examinations,physicalVisit,checkInformation,dateOfCheck,patient,patientName,patientSurname, patientBirthday}) {

const [date,setDate] = useState([])
 

useEffect(() => {

setDate(dateOfCheck)
},[date])


// const formattedDateOfCheck = moment(date).utc().format('DD/MM/YY')
    return (
      <>
  
       <Flex justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} bg={'gray.100'} rounded={'md'} p={3}>
        <Box pl={2}>
<Heading size={'md'} color={'#322F4B'}>Vizita</Heading>
       <Text fontWeight={'500'} pt={2} color={'#39B6FF'}>{dateOfCheck}</Text>
       </Box>
       <Flex alignItems={'center'} pr={2}>

{/* <DeleteCheck patient={patient}/> */}
<CheckModal patientBirthday={patientBirthday} patientName={patientName} patientSurname={patientSurname} examinations={examinations} diagnostic={diagnostic} treatment={treatment} physicalVisit={physicalVisit} dateOfCheck={dateOfCheck} checkInformation={checkInformation}/>
       </Flex>
       </Flex>

      </>
    )
}

export default CheckDetails
