import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PricingCard from '../components/PricingCard';
import Footer from '../components/Footer';
function SubscriptionPage() {
    const navigate = useNavigate();
    const {user} = useSelector((state) => state.user)  
   useEffect(() => {
    const dataHandling = async () => {
        if(user?.paymentStatus === true){
            await navigate('/')
          }else{

          }
    }
  dataHandling()
   })
  return (
    <div>
    <div className='flex-col h-screen bg-slate-100 justify-center items-center'>
<div className='headerText  text-5xl font-bold pt-20 flex justify-center items-center text-center'>
Plani i Abonimit
</div>
<h1 className='text-center pt-6 text-[#39B6FF] text-2xl font-medium'>Zgjidhni një plan që funksionon më së miri për ju</h1>
<div className='flex pt-12 justify-center flex-wrap space-x-4 items-center'>
<PricingCard priceNumber='€11.99' firstCondition='Menaxhim i numrit të palimituar të pacientëve' secondCondition='Printim i raporteve të pacientëve' planTitle='Standard' pricingDuration='muaj'/>
<PricingCard priceNumber='€14.99' firstCondition='Menaxhim i numrit të palimituar të pacientëve' secondCondition="Printim i raporteve të pacientëve" thirdCondition='Profili juaj do të jetë i shtuar në faqën e specialistëve'  planTitle='Premium' pricingDuration='muaj'/>
<PricingCard priceNumber='€150' firstCondition='Menaxhim i numrit të palimituar të pacientëve' secondCondition="Printim i raporteve të pacientëve" thirdCondition='Profili juaj do të jetë i shtuar në faqën e specialistëve'  pricingDuration='vjet'/>
</div>
    </div>
   
     </div>
  )
}

export default SubscriptionPage
