import React from "react";
import { AddButton } from "../components/AddButton";
import axios from "axios";
import {Table,Thead,Tr,Th,TableContainer} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PatientsDashboard from "../components/PatientsDashboard";
import NoPatientsAlert from "../components/NoPatientsAlert";
function HomeScreen() {
  const { user } = useSelector((state) => state.user);
  const [listOfPatients, setListOfPatients] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

 useEffect(() => {
  if(user?.paymentStatus === false){
    navigate('/subscription')
  }else{
    
  }
 })
 useEffect(() => {
  getPatientData();
    },[listOfPatients])
    useEffect(() => {
      getData();
      
      
    }, [navigate]);
  const getPatientData = async () => {
    try {
      
      const response = await axios.get(
        "https://pacienti-app-api.vercel.app/api/get-patients-by-doctor-id",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      
      if (response.data.success) {
        setLoader(true)
       setListOfPatients(response.data.data);
      }
    } catch (error) {
    }
  };

  const getData = async () => {
    try {
      const response = await axios.post(
        "https://pacienti-app-api.vercel.app/api/user/get-user-info-by-id",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
    
      if (response.data.success) {
      } else {
        localStorage.clear();
      }
    } catch (error) {
      console.log(error)
      localStorage.clear();
    }
  };

 


  const dateBuilder = (d) => {
    let months = [
      "Janar",
      "Shkurt",
      "Mars",
      "Prill",
      "Maj",
      "Qershor",
      "Korrik",
      "Gusht",
      "Shtator",
      "Tetor",
      "Nëntor",
      "Dhjetor",
    ];

    let date = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();

    return ` ${date} ${month} ${year}`;
  };

  return (
    <>
      <div className=" mt-12 p-2">
        <h1 className="text-3xl font-bold text-[#18143A] pl-12">Pacientët</h1>
        <div className="flex justify-between m-12 items-center  flex-wrap rounded p-4 bg-[#39B6FF]">
          <p className="font-bold text-white text-lg">
            {dateBuilder(new Date())}
          </p>
          <div className="flex space-x-0 sm:space-x-8 space-y-4 sm:space-y-0 pt-4 sm:pt-0 flex-wrap justify-center items-center">
            <input
              type="search"
              name=""
              id=""
              placeholder="Kërko..."
              onChange={(e) => setSearchTerm(e.target.value)}
              className="rounded p-1 bg-white placeholder-[#39B6FF] focus:outline-none  focus:border-none transition-all   "
            />
            <AddButton />
          </div>
        </div>
        <TableContainer p={12}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Emri</Th>
                <Th>Mbiemri</Th>
                <Th isNumeric>Vizita e fundit</Th>
                <Th isNumeric>Shto Vizitë</Th>
                <Th isNumeric>Historia</Th>
                <Th isNumeric>Fshij</Th>
              </Tr>
            </Thead>
            {listOfPatients.length > 0 ? (
            <PatientsDashboard listOfPatients={listOfPatients} loader={loader} searchTerm={searchTerm}/>
            ) : <NoPatientsAlert/>}
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default HomeScreen;
