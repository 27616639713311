import {
    Button,
    FormControl,
    Flex,
    Heading,
    Stack,
    Text,
    useColorModeValue,
    Link,
    Image,
    Input
  } from '@chakra-ui/react';
import axios from 'axios';
  
  import pacientiLogo from '../assets/logoLogin.png'
  import {Formik, Form } from 'formik';
import { useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
  export default function ForgotPassword() {

const onSubmit = async(actions) => {
    try {
    const response = await axios.post('https://pacienti-app-api.vercel.app/api/user/forgot-password', {
        email
    })
    if(response.data.success === true){
       toast.success(response.data.message)
actions.setSubmitting(false)
    }
     else{
      
     }

    } catch (error) {
     console.log(error)
    }
}
const [email,setEmail] = useState('')
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        flexDirection={'column'}
       >
            <Toaster/>
       <Link  w={'170px'}><Image   src={pacientiLogo}  /></Link>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}>
            
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Keni harruar fjalëkalimin?
          </Heading>
          <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={useColorModeValue('gray.800', 'gray.400')}>
            Do të merrni një email me një link rivendosjeje
          </Text>
          <Formik
          initialValues={{
            email: ''
          }}
          onSubmit={onSubmit}
          >
           {({handleSubmit,isSubmitting,}) => (
            <Form onSubmit={handleSubmit}>
 <FormControl isRequired name='email' id="email" >
            <Input
              placeholder="email-yt@shembull.com"
              _placeholder={{ color: 'gray.500' }}
              type="email"
              name='email'
              id='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        
          <Stack spacing={6}>
            <Button
            isLoading={isSubmitting}
            mt={4}
            type='submit'
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}>
              Kërko rivendosjen
            </Button>
            
          </Stack>
            </Form>
           )}
          
          </Formik>
        </Stack>
      </Flex>
    );
  }