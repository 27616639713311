import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Loader from './Loader'
function MailBox() {
const [contacts, setContacts] = useState([])
const [loader,setLoader] = useState(false)
useEffect(() => {
getContacts()

},[contacts])
const getContacts = async () => {
    try {
      
      const response = await axios.get(
        "https://pacienti-app-api.vercel.app/admin/get-contacts").catch((error) => console.log(error));
      
      if (response.data.success) {
        setLoader(true)
       setContacts(response.data.data);
      }
    } catch (error) {
        console.log(error)
    }
  };

  return (
    <div className='flex-col ml-12 mt-8'>
    <p className='font-medium text-xl'>MailBox of Contacts</p>
    <div className='flex space-x-4 flex-wrap'>
    
    {loader ? contacts.map((contact) => {
        return(
            <div key={contact?._id} className='flex-col mt-8 p-6 justify-start items-start flex-wrap w-[300px] h-auto rounded-md bg-gray-100'>
            <p><span className='font-bold'>Name:</span> {contact?.name}</p>
            <p><span className='font-bold'>Surname:</span>  {contact?.lastName}</p>
            <p><span className='font-bold'>Company:</span>  {contact?.company}</p>
            <p><span className='font-bold'>Email:</span>  {contact?.email}</p>
            <p><span className='font-bold'>Contact Message:</span>  {contact?.message}</p>
                </div>
        )
    }): <Loader/>}
   
    </div>
    </div>
  )
}

export default MailBox