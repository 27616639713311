import React from 'react'
import {
  Button,
  Flex,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

import { Col, Form,  Row,  } from "antd";
import ChangePassword from './ChangePassword';
function DoctorForm({doctor,onFinish}) {

  return (
    <Flex
    minH={'100vh'}
    align={'center'}
    justify={'center'}
    >
    <Stack
      spacing={4}
      w={'full'}
      maxW={'2xl'}
      bg={useColorModeValue('white', '#17163C')}
      rounded={'xl'}
      boxShadow={'lg'}
      p={6}
      my={12}>
      <Heading pb={4} lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
       Profili i Përdoruesit
      </Heading>
      <Form
    layout="vertical"
    onFinish={onFinish}
    initialValues={{
      ...doctor,
     
    }}
  >
    
    <Row gutter={15}>
      <Col span={8} xs={24} sm={24} lg={8}>
        <Form.Item
          required
          label="Emri"
          name="firstName"
          rules={[{ required: true }]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
      </Col>
      <Col span={8} xs={24} sm={24} lg={8}>
        <Form.Item
          required
          label="Mbiemri"
          name="lastName"
          rules={[{ required: true }]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
      </Col>
      <Col span={8} xs={24} sm={24} lg={8}>
        <Form.Item
          required
          label="Numri i telefonit"
          name="phoneNumber"
          rules={[{ required: true }]}
        >
          <Input placeholder="Phone Number" />
        </Form.Item>
      </Col>
     
      <Col span={8} xs={24} sm={24} lg={8}>
        <Form.Item
          required
          label="Adresa"
          name="address"
          rules={[{ required: true }]}
        >
          <Input placeholder="Address" />
        </Form.Item>
      </Col>
    </Row>
    <hr />
    
    <Row className='pt-6' gutter={20}>
      <Col span={8} xs={24} sm={24} lg={8}>
        <Form.Item
          required
          label="Specializimi"
          name="specialization"
          rules={[{ required: true }]}
        >
          <Input placeholder="Specializimi" />
        </Form.Item>
      </Col>
      <Col span={8} xs={24} sm={24} lg={8}>
        <Form.Item
          required
          label="Eksperienca"
          name="experience"
          rules={[{ required: true }]}
        >
          <Input placeholder="Eksperienca"/>
        </Form.Item>
      </Col>
     <Col span={8} xs={24} sm={24} lg={8}>
        <Form.Item
          required
          label="Ordinanca / Spitali"
          name="workPlace"
          rules={[{ required: true }]}
        >
          <Input placeholder="Vendi i punes"  />
        </Form.Item>
      </Col>
     
    </Row>
    <hr />
    <Row className='pt-6' gutter={20}>
      <Col span={8} xs={24} sm={24} lg={8}>
        <Form.Item
          required
          label="E-mail Adresa"
          name="email"
          rules={[{ required: true }]}
        >
          <Input type={'email'} />
        </Form.Item>
      </Col>
     <ChangePassword/>
     
    </Row>

    <div className='pt-6'>
       <Button
       type='submit'
       
            bg={'#39B6FF'}
            color={'white'}
            w="full"
            _hover={{
              bg: 'blue.500',
            }}>
            Ruaj Ndryshimet
          </Button>
    </div>
  </Form>
      
      </Stack>
     
  
  </Flex>

  )
}

export default DoctorForm