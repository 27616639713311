import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    Button,
    useDisclosure,

  } from '@chakra-ui/react';
import { useEffect } from 'react';
import CheckDetails from './CheckDetails';


function HistoryModal({id,patient,patientCheck,patientDateCheck,patientName,patientSurname, patientBirthday}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
  const [checkInfo,setCheckInfo] = useState([]);
   const [scrollBehavior, setScrollBehavior] = React.useState('inside')
useEffect(() => {
  setCheckInfo(patientCheck)
 
},[patientCheck])

    return (
      <>
      <button onClick={onOpen} className='text-[#39B6FF] hover:underline font-bold'>Shiko</button>
       
        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} scrollBehavior={scrollBehavior}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Të Dhënat e Pacientit</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
          {checkInfo.map((checkInformation) => {
           const checkDate = checkInformation.DateOfCheck;
           const formattedCheckDate= new Date(checkDate).toLocaleDateString();
           const formattedBirthday= new Date(patientBirthday).toLocaleDateString();
            return(

              <Box key={checkInformation._id} py={3} >
             
                <CheckDetails patientBirthday={formattedBirthday} patientName={patientName} patientSurname={patientSurname} dateOfCheck={formattedCheckDate} patient={patient} treatment={checkInformation.treatment} diagnostic={checkInformation.diagnostic} examinations={checkInformation.examinations} physicalVisit={checkInformation.physicalVisit}  checkInformation={checkInformation.check} />
                </Box>
            )
          })}
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Mbyll
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default HistoryModal
