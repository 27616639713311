import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
function Loader() {
  return (
    <Flex marginTop={'40px'}>
<Spinner
position={'absolute'}
left={'0'}
right={'0'}

margin={'auto'}
thickness='4px'
  emptyColor='gray.200'
  color='#39B6FF'
  size='xl'
/>
</Flex>
  )
}

export default Loader