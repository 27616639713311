import React from "react";
import {
  Flex,
  Box,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import pacientiLogo from "../assets/logoLogin.png";
import axios from "axios";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { InputControl } from "formik-chakra-ui";
function Login() {
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    try {
      const response = await axios.post("https://pacienti-app-api.vercel.app/api/user/login", values);
      if (response.data.success) {
        localStorage.setItem("token", response.data.data);
        if(response.data.subscription){
          navigate("/subscription");
        }else{
          navigate("/");
        }
        
      }
       else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Te lutem provoni perseri");
      console.log(error)
    }
  };

  return (
    <Flex
      flexDirection={"column"}
      minH={"100vh"}
      align={"center"}
      justify={"center"}
    >
      <Stack spacing={8} mx={"auto"} w={"full"} maxW={"md"} px={6}>
        <Toaster />
        <Stack align={"center"}>
          <Link pb={6} w={"170px"}>
            <Image src={pacientiLogo} />
          </Link>
          <Heading color={"blue.400"} fontSize={"4xl"}>
            Kyçu
          </Heading>
        </Stack>

        <Box
          mb={12}
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isSubmitting, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <InputControl isRequired name="email" label="E-mail" />

                  <InputControl
                    pt={3}
                    isRequired
                    name="password"
                    inputProps={{ type: "password" }}
                    label="Fjalëkalimi"
                  />
                  <Stack spacing={10}>
                    <Stack
                      mt={2}
                      direction={{ base: "column", sm: "row" }}
                      align={"end"}
                      justify={"end"}
                    >
                      <Link href="/forgot-password" color={"blue.400"}>
                        Keni harruar fjalëkalimin?
                      </Link>
                    </Stack>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      bg={"blue.400"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                    >
                      Identifikohu
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;
