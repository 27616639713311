import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { setUser } from '../redux/Slices/UserSlice';
function ProtectedRoutes(props) {
    const {user} = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
const getUser = async() => {
    try {
        const response = await axios.post('https://pacienti-app-api.vercel.app/api/user/get-user-info-by-id',{ token: localStorage.getItem('token') }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        if(response.data.success) {
        dispatch(setUser(response.data.data));
        }else{
            localStorage.clear();
            navigate('/login');
        }
    } catch (error) {
        localStorage.clear();
        navigate('/login');
    }
}
    useEffect(() => {
    if(!user) {
        getUser();
    }
    },[user])
    if(localStorage.getItem('token')){
        return props.children
    }else{
        return <Navigate to='/login'/>
    }
 
}

export default ProtectedRoutes