import React, { useEffect } from 'react'
import { Image } from '@chakra-ui/react'
import WelcomePng from '../assets/TaeAugust07-removebg.png';
import { useSelector } from 'react-redux';
import {FaUser} from 'react-icons/fa';
import DashboardComponent from '../components/DashboardComponent';
import DashboardImage from '../assets/dashboardImage.png'
import DashboardImage2 from '../assets/dashboard2.png'
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.user)  
 useEffect(() => {
  if(user?.paymentStatus === false){
    navigate('/subscription')
  }
  else if(user?.isAdmin === true){
    navigate('/admin')
  }
  else{
    
  }
 })
  return (
    <div className='flex-col justify-center items-center p-8'>
<div className='bg-[#C6EBFE] p-4 md:p-0 mt-8 h-[120px] w-full flex justify-evenly  items-center rounded-xl '>
  <div className='justify-start items-center flex-col'>
 <h1 className='font-medium text-[#0F0D33] text-xl'>Mirësevjen përsëri, {user?.firstName}! </h1>
 <h1 className='pt-3 text-sm'>Menaxhoni pacientët dhe lehtësoni punën tuaj!</h1>
  </div>
  
    <Image w={'300px'} className='pb-8  hidden sm:flex scale-90 bottom-0 ' src={WelcomePng}/>
  
  <div>

  </div>
</div>
<div className='flex flex-col sm:flex-row  sm:space-x-8 space-y-8 sm:space-y-0 space-x-0  justify-evenly items-center mt-16'>
<DashboardComponent to='patients' icon={<FaUser/>} text='Shiko pacientët'/>

</div>
<div className='mt-16 flex flex-wrap justify-center space-x-0 space-y-6 md:space-y-0 md:space-x-6 items-center'>
<Image className='w-[550px]' src={DashboardImage}/>
<Image className='w-[550px]' src={DashboardImage2}/>
</div>
    </div>
  )
}

export default Dashboard