import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import {store} from './redux/Store'
import { PatientProvider } from './context/PatientData';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PatientProvider>
  <BrowserRouter>
 <ChakraProvider>
    <App />
    </ChakraProvider>
    </BrowserRouter>
    </PatientProvider>
    </Provider>
);

