import React, { createContext, useState } from 'react';

const PatientContext = createContext();

const PatientProvider = ({ children }) => {
  const [tableData, setTableData] = useState([]);

  const addPatientToTable = (newPatient) => {
    // Update the table data by adding the new data
    setTableData([...tableData, newPatient]);
  };

  return (
    <PatientContext.Provider value={{ tableData, addPatientToTable }}>
      {children}
    </PatientContext.Provider>
  );
};

export { PatientContext, PatientProvider };