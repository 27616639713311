'use client'

import {
  Flex,
  Stack,
  Heading,
  Text,
  Button,
  useColorModeValue,
  Image,
  Link
} from '@chakra-ui/react'
import EmailSentIllustration from '../assets/send_7465908.png'

export default function EmailConfirmationPage() {
  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      py={12}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        boxShadow={'2xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        p={10}
        spacing={8}
        align={'center'}>
        <Image src={EmailSentIllustration} w={'82px'}/>
        <Stack align={'center'} spacing={2}>
          <Heading
            textTransform={'uppercase'}
            fontSize={'3xl'}
            color={useColorModeValue('gray.800', 'gray.200')}>
            Email sent
          </Heading>
          <Text paddingTop={'12px'} textAlign={'center'} fontSize={'lg'} color={'gray.500'}>
            An email has been sent to your email.<br></br> Please verify your account in order to log in.
          </Text>
        </Stack>
        <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={'full'}>
        
          <Button
            bg={'white'}
            rounded={'full'}
            color={'blue.400'}
            flex={'1 0 auto'}
            _hover={{ bg: 'white' }}
            _focus={{ bg: 'white' }}>
               <Link href='/login'>
            Go Back
            </Link>
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}

