import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import DoctorForm from './DoctorForm';
export default function DoctorProfileEdit() {
  const { user } = useSelector((state) => state.user);
  const [doctor,setDoctor] = useState(null);
  const params = useParams();
    const navigate = useNavigate();
 
   useEffect(() => {
    if(user?.paymentStatus === false){
      navigate('/subscription')
    }else{
      
    }
   })
    const onFinish = async (values) => {
      try {
        
        const response = await axios.post(
          "https://pacienti-app-api.vercel.app/api/user/update-doctor-profile",
          {
            ...values,
            userId: user._id,
           
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
     
        if (response.data.success) {
          toast.success(response.data.message);
          window.location.reload();
          
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
       
        toast.error("Diçka shkoi keq. Të lutem provoni përsëri");
      }
    };
  const getDoctorData = async () => {
    try {
     
      const response = await axios.post(
        "https://pacienti-app-api.vercel.app/api/user/get-doctor-info-by-user-id",
        {
          userId: params.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

     
      if (response.data.success) {
        setDoctor(response.data.data);
      }
    } catch (error) {
      console.log(error);
     
    }
  };

  useEffect(() => {
    getDoctorData();
    
  });


  
  return (
    <>
    <Toaster/>
   {doctor && <DoctorForm doctor={doctor} onFinish={onFinish} />}
   </>
  );
}