import * as React from "react";
import { Box,Flex, Heading, Link, Image,Stack,Checkbox} from "@chakra-ui/react";
import { Formik } from "formik";
import {InputControl,SubmitButton} from "formik-chakra-ui";
import * as Yup from "yup";
import axios from 'axios';
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// IMPORTS ENDS HERE

const registerLogo = require("../assets/logoLogin.png");
const RegisterIllustration = require("../assets/registerIllustration.jpg")

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  specialization: "",
  address: '',
  phoneNumber:'',
  experience: '',
  workPlace: '',

};

const validationSchema = Yup.object({
  firstName: Yup.string().required('Fushë e detyruar'),
  lastName: Yup.string().required('Fushë e detyruar'),
  email: Yup.string().required('Fushë e detyruar').email('Të lutem shënoni në mënyrë të duhur'),
  password: Yup.string().required('Fushë e detyruar').min(8, 'Duhet të jetë së paku 8 karaktere.'),
  specialization: Yup.string().required('Fushë e detyruar'),
  experience: Yup.string().required('Fushë e detyruar'),
  phoneNumber: Yup.string().required('Fushë e detyruar'),
  address: Yup.string().required('Fushë e detyruar'),
  workPlace: Yup.string().required('Fushë e detyruar')
});

const Form = () => {
  
    const navigate = useNavigate();
  return (
<>
<Flex  justifyContent={'center'} alignItems={'start'}>
 <div className="w-1/2 overflow-hidden xl:flex hidden"> <Image w={'auto'}  src={RegisterIllustration}/></div>
<div className="lg:w-1/2 w-full ">
    <Formik
    
      initialValues={initialValues}
      onSubmit={async(values) => {
        try{
          const response = await axios.post('https://pacienti-app-api.vercel.app/api/user/register',values);
          if(response.data.success) {
            toast.success("Përdoruesi u regjistrua me sukses")
            navigate('/emailConfirmation*291029dnsjsbfybs/250898')
          }else{
            toast.error("Përdoruesi ekziston")
          }
          }catch(error){
         toast.error('Të lutem provoni përsëri')
         console.log(error)
          }
          
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, values, errors, isSubmitting }) => (
         <Flex
         background={'white'}
         minH={'100vh'}
         align={'center'}
         justify={'center'}
         flexDirection={'column'}
       onSubmit={handleSubmit as any}
      padding={6}
         as="form"
         
         >
          <Toaster/>
                <Link pb={6} w={'170px'}><Image   src={registerLogo}  /></Link>
            <Heading fontSize={'4xl'} textAlign={'center'} color='#39b6ff'>
              Regjistrohu
            </Heading>
   <Box
   mt={6}
   w={'full'} maxW={'md'}
   boxShadow={'lg'}
  p={10}
   >
   
    <Flex direction={'row'}  alignItems={'center'}>
        
          <InputControl  isRequired pr={3} name="firstName" label="Emri" />
          <InputControl isRequired name="lastName" label="Mbiemri" />
         
          </Flex>
          <InputControl  isRequired name="email" label="E-mail" pt={3} />
          <InputControl
          pt={3}
          isRequired
            name="password"
            inputProps={{ type: "password" }}
            label="Fjalëkalimi"
          />
          <InputControl  isRequired name="address" label="Adresa" pt={3} />
          <InputControl  isRequired name="phoneNumber" label="Numri i Telefonit" placeholder="045XXX"   pt={3} />
         <InputControl pt={3} isRequired name="specialization" label="Specializimi" placeholder="Oftalmolog" />
         <InputControl pt={3} isRequired name="experience"  label="Eksperienca" placeholder="Oftalmolog" />
         <InputControl pt={3} isRequired name="workPlace" label="Ordinanca / Spitali"  /> 
         <Checkbox isRequired pt={5} size='md' colorScheme='blue' defaultChecked>
    Bie dakord me <a className="text-[#3282CE] font-medium" href="https://pacientii.com/kushtet-e-perdorimit">Kushtet e përdorimit</a> dhe <a className="text-[#3282CE] font-medium" href="https://pacientii.com/politikat-e-privatesise">Politikat e privatësisë.</a>
  </Checkbox>
          <Stack  pt={8}>
          <SubmitButton
          isLoading={isSubmitting}
                  
            
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                 Regjistrohu
                </SubmitButton>
          
          </Stack>
        
          </Box>
         
        </Flex>
       
      )}
    </Formik>
    </div>
    </Flex>
    </>
  );
};

export default Form;
