import React, {useEffect,useState} from 'react'
import axios from 'axios'
import {
    Td,
  } from "@chakra-ui/react";
function PatientNumberById({doctorId}) {
    const [numberOfPatientsByDoctorId,setNumberOfPatientsByDoctorId] = useState(0)
    useEffect(() => {
        getNumberOfPatientsByDoctorId()
      },[numberOfPatientsByDoctorId])
      
      const getNumberOfPatientsByDoctorId = async() => {
        await axios.get(`https://pacienti-app-api.vercel.app/admin/patients-of-doctor-count/${doctorId}`).then((response) => {
          setNumberOfPatientsByDoctorId(response.data.documentCount)
        })
        } 
  return  <Td>{numberOfPatientsByDoctorId}</Td>
    
  
}

export default PatientNumberById