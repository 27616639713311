import React, { useEffect, useState } from 'react';
import DoctorTable from '../components/DoctorTable';
import PacientiLogo from '../assets/logo1-removebg.png'
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Link
} from '@chakra-ui/react'
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiUsers
} from 'react-icons/fi'
import {BiNotepad} from 'react-icons/bi'
// import Screenshot from '../assets/Screenshot 2023-07-31 at 1.52.31 PM.png'

import {useSelector} from 'react-redux'
import axios from 'axios';

const LinkItems = [
  { name: 'Home', icon: FiHome, linkProp:'https://pacientii.com' },
  { name: 'Users', icon: FiTrendingUp, linkProp:'#users' },
  { name: 'Mail', icon: FiCompass,linkProp:'/mail' },
  { name: 'Doctors', icon: FiUsers, linkProp:'https://pacientii.com/uploadDoctor*192kol1o2kdh35gsga' },
  { name: 'Todos', icon: BiNotepad, linkProp:'hello' },
]

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="xl" fontFamily="monospace" fontWeight="bold">
          <Image paddingTop={6} w={40} src={PacientiLogo}/>
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Box mt={4}>
      {LinkItems.map((link) => (
        <NavItem mt={1}  key={link.name} icon={link.icon}>
          <Link href={link.linkProp}>{link.name}</Link>
        </NavItem>
      ))}
      </Box>
    </Box>
  )

}

const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        transition={'0.2s'}
        _hover={{
          bg: '#100C37',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}

const MobileNav = ({ onOpen, ...rest }) => {
  const {user} = useSelector((state) => state.user)
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        <Image w={36} src={PacientiLogo}/>
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                   'https://ui-avatars.com/api/?name=AM'
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">Andi Mashkulli</Text>
                  <Text fontSize="xs" color="gray.600">
                    Founder
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <Link href={`/doctor/profile/${user?._id}`}><MenuItem>Profile</MenuItem></Link>
              <MenuDivider />
              <MenuItem onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                  
                }}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const Admin = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [userCount, setUserCount] = useState(0);
  const [patientCount, setPatientCount] = useState(0);
  const [doctorCount, setDoctorCount] = useState(0);
  useEffect(() => {
    numberOfPatients();
    numberOfUsers()
    numberOfDoctors()
   },[])
  const numberOfPatients = async() => {
   await  axios.get('https://pacienti-app-api.vercel.app/admin/patientCount')
    .then(response => {
      setPatientCount(response.data.patientCount);
    })
    .catch(error => {
      console.error('Error fetching user count:', error);
    });
   
  }
  const numberOfUsers = async() => {
    await axios.get('https://pacienti-app-api.vercel.app/admin/userCount')
    .then(response => {
      setUserCount(response.data.userCount);
    })
    .catch(error => {
      console.error('Error fetching user count:', error);
    });
  }
   const numberOfDoctors = async() => {
    await axios.get('https://pacienti-app-api.vercel.app/admin/doctorCount')
    .then(response => {
      setDoctorCount(response.data.doctorCount);
    })
    .catch(error => {
      console.error('Error fetching user count:', error);
    });
  }
  return (
    <Box minH="100vh" >
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Text fontSize={'2xl'} fontWeight={'bold'}>Dashboard</Text>
<Box>
  <Flex  pt={8} flexDir={'row'} justify={'space-around'} alignItems={'center'}> {/*This is the main box that will
  keep three cards */}
<Flex shadow={'sm'} mr={4}   flexDirection={'column'} p={4} w={'full'} h={'auto'} rounded={'lg'}  backgroundColor={'#E6F6FA'} justifyContent={'flex-start'} alignItems={'flex-start'}>
<Text fontSize={'lg'} fontWeight={'bold'}>Numri i mjekëve</Text>
<Text pt={6} fontSize={'3xl'} fontWeight={'medium'}>{userCount}</Text>
</Flex>

<Flex mr={4} flexDirection={'column'} p={4} w={'full'} h={'auto'} rounded={'lg'} backgroundColor={'#EEFDEF'} justifyContent={'flex-start'} alignItems={'flex-start'}>
<Text fontSize={'lg'} fontWeight={'bold'}>Numri i mjekëve në web</Text>
<Text pt={6} fontSize={'3xl'} fontWeight={'medium'}>{doctorCount}</Text>
</Flex>

<Flex mr={4} flexDirection={'column'} p={4} w={'full'} h={'auto'} rounded={'lg'}  backgroundColor={'#FFEFE1'} justifyContent={'flex-start'} alignItems={'flex-start'}>
<Text fontSize={'lg'} fontWeight={'bold'}>Numri i pacientëve</Text>
<Text pt={6} fontSize={'3xl'} fontWeight={'medium'}>{patientCount}</Text>
</Flex>

  </Flex>
  {/* Blue Color #E6F6FA */}
  {/* Green Pistachio Color #EEFDEF */}
  {/* Orange color #FFEFE1 */}
</Box>
<Box id='users'>
<DoctorTable/>
</Box>
      </Box>
    </Box>
  )
}

export default Admin