import React from 'react'
import {BsArrowRight} from 'react-icons/bs'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
useDisclosure,
Box,
Text,
Link
} from '@chakra-ui/react'
import { useState } from 'react'
import { useEffect } from 'react'
import PrintPage from './PrintPage'



function CheckModal({checkInformation,physicalVisit,diagnostic,treatment,examinations,dateOfCheck,patientName,patientSurname, patientBirthday}) {


  const { isOpen, onOpen, onClose } = useDisclosure()
  const [size, setSize] = React.useState('xl')
  const [patientsCheck,setPatientsCheck] = useState([]);

  useEffect(() => {
setPatientsCheck(checkInformation)

  },[])
 
  return (
    <>
     <Button onClick={onOpen}><BsArrowRight/></Button>

      <Modal isOpen={isOpen} onClose={onClose} size={size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Kontrolla</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <Text color={'#39B6FF'} fontSize={'lg'} fontWeight={'medium'}>Anamneza</Text>
           <Box mt={2} background={'gray.100'}>
    <Text>
{patientsCheck}
    </Text>


            </Box>
            {
physicalVisit ? (
  <>
            <Text pt={4} color={'#39B6FF'} fontSize={'lg'} fontWeight={'medium'}>Vizita fizike</Text>
           <Box mt={2} background={'gray.100'}>
    <Text>
{physicalVisit}
    </Text>


            </Box>
            </>
            ) : ''
            
            }
            <Text pt={4} color={'#39B6FF'} fontSize={'lg'} fontWeight={'medium'}>Egzaminimet</Text>
           <Box mt={2} background={'gray.100'}>
    <Text>
{examinations}
    </Text>
            </Box>
            <Text pt={4} color={'#39B6FF'} fontSize={'lg'} fontWeight={'medium'}>Diagnoza</Text>
           <Box mt={2} background={'gray.100'}>
    <Text>
{diagnostic}
    </Text>
            </Box>
            <Text pt={4} color={'#39B6FF'} fontSize={'lg'} fontWeight={'medium'}>Trajtimi / Terapia</Text>
           <Box mt={2} background={'gray.100'}>
    <Text>
{treatment}
    </Text>


            </Box>
          </ModalBody>

          <ModalFooter>
          <PrintPage patientBirthday={patientBirthday} patientName={patientName} patientSurname={patientSurname} treatment={treatment} dateOfCheck={dateOfCheck} physicalVisit={physicalVisit} diagnostic={diagnostic} examinations={examinations} patientsCheck={patientsCheck} patientBirthday={patientBirthday}/>
           
           <Button color={'black'} background={'gray.200'}  mr={3} onClick={onClose}>
              Mbyll
            </Button>
          
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CheckModal
