import React from 'react';
import {RiMentalHealthFill} from 'react-icons/ri'
import {AiOutlineUser} from 'react-icons/ai'
import {BiHelpCircle} from 'react-icons/bi';
import {VscSignOut} from 'react-icons/vsc';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Link
} from '@chakra-ui/react';



import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Outlet } from 'react-router-dom';
import Logo from '../assets/logo1-removebg.png'







export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {user} = useSelector((state) => state.user)  
  return (
    <>
      <Box shadow={'md'} px={4} fontFamily={'sans-serif'}>
        <Flex h={20} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            color={'#18143A'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
           <Link href='/' pt={1}> <Box width={'36'}><img alt='' src={Logo}/></Box></Link>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
                {
                  user?.paymentStatus === false ? "" : (
               <Link
    px={2}
    py={1}
    rounded={'md'}
   color={'#18143A'}
  
    _hover={{
        
      textDecoration: 'none',
      
    }}
    className='font-normal  font-sans flex items-center gap-x-2'
    >
<a href='/patients' className='flex items-center '><RiMentalHealthFill className='text-[#39B6FF] mr-2' size={22}/>Pacientët</a>
  </Link>
                  )
}
  {user?.verified === false ? <Link bg={'red.100'} p={1} rounded={'md'} fontWeight={'medium'}>Të lutem verifikoni emailin tuaj!</Link> : ""}
 
 
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
           
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                pr={'2'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  src={
                    `https://ui-avatars.com/api/?name=${user?.firstName+"+"+ user?.lastName}&background=39B6FF&color=fff`
                  }
                />
              </MenuButton>
              <MenuList >
                <Flex py={4} px={4}>
                <Avatar
                  size={'md'}
                  src={
                  `https://ui-avatars.com/api/?name=${user?.firstName+"+"+ user?.lastName}&background=39B6FF&color=fff`
                  }
                  
                />
                <Flex pl={2} flexDirection={'column'}>
                <p className='font-bold  text-[#18143A]'>{user?.firstName + " " + user?.lastName}</p>
                <p className='rounded  font-semibold  text-sm text-[#39B6FF]' >{user?.specialization}</p>
                </Flex>
                    </Flex>
                    <Link href={`/doctor/profile/${user?._id}`} > <MenuItem className='flex gap-x-2 items-center font-medium'><AiOutlineUser className='text-[#39B6FF]' />Profili</MenuItem></Link>
                <MenuItem className='flex gap-x-2 items-center font-medium'><BiHelpCircle className='text-[#39B6FF]'/>Ndihmë</MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                  
                }} className='flex gap-x-2 items-center font-medium'><VscSignOut className='text-[#39B6FF]' />
                  
                Dil</MenuItem>
                
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
            <Link
    px={2}
    py={1}
    rounded={'md'}
   color={'#18143A'}
  
    _hover={{
        
      textDecoration: 'none',

    }}
    className='font-medium  font-sans flex items-center gap-x-2'
    >
<a href='/patients' className='flex'><RiMentalHealthFill className='text-[#39B6FF]' size={22}/>Pacientët</a>
  </Link>

            </Stack>
          </Box>
        ) : null}
      </Box>
<Outlet/>
    </>
  );
}