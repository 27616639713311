import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import axios from "axios";

function SubscriptionButton(props) {
  const {
    normalSubscriptionn,
    paymentStatus,
    premiumSubscriptionn,
    yearlySubscriptionn,
    doctorId,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);
  const [normalSubscription, setNormalSubscription] = useState("");
  const [premiumSubscription, setPremiumSubscription] = useState("");
  const [yearlySubscription, setYearlySubscription] = useState("");
  const [mainPayment, setMainPayment] = useState();
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  const calculateSubscriptionStatus = (subscription, paymentStatus) => {
    return subscription && paymentStatus ? "True" : "False";
  };

  const mainPaymentStatus = (paymentStatus) => {
    return paymentStatus ? "True" : "False";
  };

  useEffect(() => {
    setNormalSubscription(
      calculateSubscriptionStatus(normalSubscriptionn, paymentStatus)
    );
    setPremiumSubscription(
      calculateSubscriptionStatus(premiumSubscriptionn, paymentStatus)
    );
    setYearlySubscription(
      calculateSubscriptionStatus(yearlySubscriptionn, paymentStatus)
    );
  }, [
    normalSubscriptionn,
    premiumSubscriptionn,
    yearlySubscriptionn,
    paymentStatus,
  ]);
  useEffect(() => {
    setMainPayment(mainPaymentStatus(paymentStatus));
  });
  const subscriptionTypes = [
    { type: "mainPayment", label: "Statusi pagesës", state: mainPayment },
    { type: "normal", label: "Plani Standard 35€", state: normalSubscription },
    { type: "premium", label: "Plani Premium 45€", state: premiumSubscription },
    { type: "yearly", label: "Plani Vjetorë 150€", state: yearlySubscription },
  ];
  const addSubscription35 = async () => {
    await axios
      .put(`https://pacienti-app-api.vercel.app/admin/addSub30/${doctorId}`)
      .then()
      .catch((error) => console.log(error));
  };
  const removeSubscription35 = async () => {
    await axios
      .put(`https://pacienti-app-api.vercel.app/admin/removeSub30/${doctorId}`)
      .then()
      .catch((error) => console.log(error));
  };

  const addSubscription45 = async () => {
    await axios
      .put(`https://pacienti-app-api.vercel.app/admin/addSub45/${doctorId}`)
      .then()
      .catch((error) => console.log(error));
  };
  const removeSubscription45 = async () => {
    await axios
      .put(`https://pacienti-app-api.vercel.app/admin/removeSub45/${doctorId}`)
      .then()
      .catch((error) => console.log(error));
  };

  const addSubscription150 = async () => {
    await axios
      .put(`https://pacienti-app-api.vercel.app/admin/addSub150/${doctorId}`)
      .then()
      .catch((error) => console.log(error));
  };
  const removeSubscription150 = async () => {
    await axios
      .put(`https://pacienti-app-api.vercel.app/admin/removeSub150/${doctorId}`)
      .then()
      .catch((error) => console.log(error));
  };

  const addMainPayment = async () => {
    await axios
      .put(`https://pacienti-app-api.vercel.app/admin/addPaymentStatus/${doctorId}`)
      .then()
      .catch((error) => console.log(error));
  };
  const removeMainPayment = async () => {
    await axios
      .put(`https://pacienti-app-api.vercel.app/admin/removePaymentStatus/${doctorId}`)
      .then()
      .catch((error) => console.log(error));
  };
  const toggleSubscription = async (subscriptionType) => {
    if (subscriptionType === "normal") {
      if (normalSubscription == "False") {
        await addSubscription35();
      } else {
        await removeSubscription35();
      }
    }
    if (subscriptionType === "premium") {
      if (premiumSubscription == "False") {
        await addSubscription45();
      } else {
        await removeSubscription45();
      }
    }
    if (subscriptionType === "yearly") {
      if (yearlySubscription == "False") {
        await addSubscription150();
      } else {
        await removeSubscription150();
      }
    }
    if (subscriptionType === "mainPayment") {
      if (mainPayment == "False") {
        await addMainPayment();
      } else {
        await removeMainPayment();
      }
    }
  };

  // Performance optimization code. In this upper code it is much more efficient when you calculate the 2 main arguments and add the state value true or false.

  return (
    <>
      <button
        onClick={onOpen}
        className="text-[#100C3A] hover:underline font-medium"
      >
        Shiko
      </button>

      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={scrollBehavior}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Plani i Abonimit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {subscriptionTypes.map((sub) => (
              <Flex
                key={sub.type}
                mt={3}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text fontWeight={"medium"}>{sub.label}</Text>
                <span
                  className={`mr-4 w-16 p-2 text-center text-${
                    sub.state === "True" ? "[#198754]" : "[#FFEADD]"
                  } bg-${
                    sub.state === "True" ? "[#CCFFC0]" : "[#FF6666]"
                  } rounded-md font-bold`}
                >
                  {sub.state}
                </span>
                <Button
                  onClick={() => {
                    toggleSubscription(sub.type);
                    window.location.reload();
                  }}
                >
                  {sub.state === "True" ? "-" : "+"}
                </Button>
              </Flex>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              color={"#100C3A"}
              bg={"#E6F6FA"}
              _hover={{ bg: "#EEFEEF", color: "#100C3A" }}
              mr={3}
              onClick={onClose}
            >
              Mbyll
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SubscriptionButton;
