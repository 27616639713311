import React from 'react'
import { Link } from 'react-router-dom';
function DashboardComponent(props) {
    const {text,icon,to} = props;
  return (
   
    <div className='flex w-full rounded-xl p-4 justify-center border border-[#60C3FC] items-center space-x-4'>
{icon}
<Link to={`${to}`}><h1 className='text-lg font-medium text-[#0F0D33]'>{text}</h1></Link>
</div>

  )
}

export default DashboardComponent