import React from 'react'
import {VscEmptyWindow} from 'react-icons/vsc'
import { Flex,Text } from '@chakra-ui/react'
import { AddButton } from './AddButton'
function NoPatientsAlert() {
  return (
    <Flex marginTop={'50px'}>
        <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} position={'absolute'} left={'0'} right={'0'} margin={'auto'}>
        <VscEmptyWindow color='#39B6FF' size={'32px'}/>
        <Text paddingTop={5}>Ju nuk keni ndonje pacient</Text>
        <Flex marginTop={'15px'}>
        <AddButton/>
        </Flex>
        </Flex>
    </Flex>
  )
}

export default NoPatientsAlert