import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Text,
    Center,
    Flex
  } from '@chakra-ui/react'
  import { Toaster } from 'react-hot-toast';
  import toast from 'react-hot-toast';
import { RiDeleteBinLine } from 'react-icons/ri'
import axios from 'axios'
// import {CgDanger} from 'react-icons/cg'
import {WarningTwoIcon} from '@chakra-ui/icons'
  function DeletePatient({patientId}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const deletePatient = async(id) => {
        await axios.delete(`https://pacienti-app-api.vercel.app/api/delete/${id}`).then((response) => {
        
        })
    }
    return (
      <>
      <Toaster/>
        <Button bg={'white'} onClick={onOpen}><RiDeleteBinLine className='text-[#39B6FF] ' size={18}/></Button>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
          
            <Center mt={6}>
           <WarningTwoIcon w={16} h={16} color="red.300"/>
           </Center>
            <ModalCloseButton />
            <ModalBody mt={6}>
             <Text align={'center'}>A jeni të sigurtë që doni ta fshini pacientin?</Text>

            </ModalBody>
  <Center mt={2}>
            <ModalFooter >
             
            <Button mr={3} 
            onClick={() => {
              deletePatient(patientId)
              onClose()
              toast.success("Pacienti u fshij me sukses!")

            }}
            colorScheme={'red'}>Fshij</Button>
              <Button   onClick={onClose}>
                Mbyll
              </Button>
              
            </ModalFooter>
            </Center>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default DeletePatient