import { PhoneIcon } from "@chakra-ui/icons";
import {
  Text,
  Center,
  Heading,
  HStack,
  Stack,
  Link,
  VStack,
  UnorderedList,
  ListItem,
  StackDivider,
  Button,
  Image,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import ReactToPrint from "react-to-print";
import PacientiLogo from '../assets/logoProfile2.png'
import {MdLocationPin} from 'react-icons/md'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {IoMdMail} from 'react-icons/io'
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const PrintPage = (props) => {
  const {treatment,physicalVisit, diagnostic, examinations, patientsCheck, dateOfCheck,patientName,patientSurname,patientBirthday  } = props;
  const navigate = useNavigate();
  const [scrollBehavior, setScrollBehavior] = useState('inside')
  const ref = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {user} = useSelector((state) => state.user) 
  
  return (
    <>
 
 <Button colorScheme="blue" mr={3}  onClick={onOpen}>
       Printo
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        scrollBehavior={scrollBehavior}
        size={'full'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Printo Kontrollën</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Center isOpen={isOpen}>
    <Flex flexDirection={'column'} justify={'center'} align={'center'}>
  
     <Stack ref={ref} width={'900px'} shadow={'xl'}>
      
        <Flex mt={14} justify={'space-between'}  alignItems={'start'}>
        <Heading fontWeight={'700'} ml={12} color={'#17153B'} fontSize={'6xl'}>{user?.workPlace}</Heading>
        <Image mr={12}  width={'130px'} src={PacientiLogo}/>
        </Flex>
        <Flex   flexDirection={'column'}  alignItems={'start'}>
       <Text ml={12} fontSize={'2xl'} fontWeight={'600'} color={'#40B1F4'}>Dr. <span className="text-[#17153B]">{user?.firstName}  {user?.lastName}</span></Text>
       <Text ml={12} fontSize={'md'} fontWeight={'600'}   color={'#17153B'}>Specialist/e: {user?.specialization}</Text>
       <Flex mt={10} w={'full'} h={'2px'} backgroundColor={'#40B1F4'} ></Flex>
        </Flex>
        <Flex pt={8} justify={'space-between'}  alignItems={'center'}>
        <Heading fontSize={'2xl'} fontWeight={'500'} ml={12} color={'#17153B'}>Raporti mjekësor:</Heading>
        <Text mr={12} fontSize={'md'} fontWeight={'600'}   color={'#17153B'}>Data e vizitës: {dateOfCheck}</Text>
        </Flex>
        <Flex pt={4} flexDirection={'column'}  >
        <Text  fontWeight={'500'} ml={12} color={'#17153B'}>Emri i Pacientit: <span className=" font-bold">{patientName}  {patientSurname}</span></Text>
        <Text pt={3} ml={12} fontSize={'md'} fontWeight={'500'}   color={'#17153B'}>Datëlindja: <span className=" font-bold">{patientBirthday}</span></Text>
        <Text pt={4} ml={12} fontSize={'xl'} fontWeight={'600'}   color={'#17153B'}>Anamneza: <span className=" text-lg leading-loose  underline decoration-[#40B1F4] font-medium ">{patientsCheck}</span></Text>
        <Text pt={6} ml={12} fontSize={'lg'} fontWeight={'600'}   color={'#17153B'}>Vizita Fizike: <span className=" leading-loose  underline decoration-[#40B1F4] font-medium">{physicalVisit}</span></Text>
        <Text pt={6} ml={12} fontSize={'lg'} fontWeight={'600'}   color={'#17153B'}>Egzaminimet: <span className=" leading-loose  underline decoration-[#40B1F4] font-medium">{examinations}</span></Text>
        <Text pt={6} ml={12} fontSize={'lg'} fontWeight={'600'}   color={'#17153B'}>Diagnoza: <span className=" leading-loose  underline decoration-[#40B1F4] font-medium">{diagnostic}</span></Text>
        <Text pt={6} ml={12} fontSize={'lg'} fontWeight={'600'}   color={'#17153B'}>Terapia: <span className=" leading-loose  underline decoration-[#40B1F4] ">{treatment}</span></Text>
        </Flex>
        <Flex pr={12} align={'end'} justify={'center'} flexDirection={'column'} pt={12}>
<Flex h={'2px'}  w={'300px'} background={'#40B1F4'}></Flex>
<Text pt={2} fontSize={'md'}>Nënshkrimi i specialistit mjekësor</Text>
        </Flex>
  <footer className="pt-12">
        <Flex p={4} mt={8} background={'#40B1F4'} justify={'start'} align={'center'}>
          <Flex flexDirection={'row'} align={'center'} justify={'space-between'}>
<Flex pl={4}  flexDirection={'row'} alignItems={'center'}>
<MdLocationPin className="text-white"/>
<Text color={'white'} pl={3}>{user?.address}</Text>
</Flex>
<Flex pl={48} flexDirection={'row'} alignItems={'center'}>
<BsFillTelephoneFill color={'white'}/>
<Text color={'white'} pl={3}>0{user?.phoneNumber}</Text>
</Flex>
<Flex pl={40} flexDirection={'row'} alignItems={'center'}>
<IoMdMail color={'white'}/>
<Text color={'white'} pl={3}>{user?.email}</Text>
</Flex>
          </Flex>
        </Flex>
        </footer>
     </Stack>
    
     
     </Flex>
     </Center>

          </ModalBody>
          <ModalFooter>
          <HStack divider={<StackDivider />} >
       
        <div>
          <ReactToPrint
            trigger={() => (
              <Button
                colorScheme="blue"
                w="max-content"
               
              >
                Printo Kontrollën
              </Button>
            )}
            content={() => ref.current}
          />
        </div>
      </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
   
    </>
  );
};

export default PrintPage;