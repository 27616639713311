import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
  } from "@chakra-ui/react";
import moment from 'moment'
import SubscriptionButton from './SubscriptionButton'
import PatientNumberById from './PatientNumberById';
const DoctorTable = () => {
const [doctors, setDoctors] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

useEffect(() => {
getDoctorData();

},[])

const getDoctorData = async() => {
try {
    const response = await axios.get('https://pacienti-app-api.vercel.app/admin/get-doctors', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
     if(response.data.success){
        setDoctors(response.data.data);
     }
} catch (error) {
    console.log(error);
}
}




  return (
    <>
        <div className=" mt-12 p-2">
        <h1 className="text-2xl font-bold text-[#18143A] ">Përdoruesit</h1>
        <div className="flex justify-between m-3 mt-6  items-center  flex-wrap rounded p-4 bg-[#E6F6FA]">
         
          <div className="flex space-x-0 sm:space-x-8 space-y-4 sm:space-y-0 pt-4  sm:pt-0 flex-wrap justify-center items-center">
            <input
              type="search"
              name=""
              id=""
              placeholder="Kërko..."
              onChange={(e) => setSearchTerm(e.target.value)}
              className="rounded p-1 bg-white placeholder-[#110C39] focus:outline-none  focus:border-none transition-all   "
            />
           </div>
           </div>
          </div>
        
         <TableContainer mt={4} p={3}>
          <Table variant='striped'>
            <Thead>
              <Tr>
                <Th>Emri</Th>
                <Th>Mbiemri</Th>
                <Th>Roli</Th>
                <Th>Numri i Telefonit</Th>
                <Th>Vendi i Punës</Th>
                <Th>Pacientët</Th>
                <Th>Data e Krijimit</Th>
                <Th>Abonimi</Th>
              </Tr>
            </Thead>
            <Tbody>
              {doctors
                .filter((doctor) => {
                  if (searchTerm === "") {
                    return doctor;
                  } else if (
                    doctor.firstName
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase()) ||
                    doctor.lastName
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
                  ) {
                    return doctor;
                  }
                })
                .map((doctor) => {
                 
                   
                  const doctorCreatedAt = doctor.createdAt
                  const formattedData = moment(doctorCreatedAt)
                    .utc()
                    .format("DD/MM/YY");

                  return (
                    <Tr key={doctor._id}>
                      <Td>{doctor.firstName}</Td>
                      <Td>{doctor.lastName}</Td>
                      <Td>{doctor.specialization}</Td>
                      <Td>{doctor.phoneNumber}</Td>
                      <Td>{doctor.workPlace}</Td>
                      <Td><PatientNumberById doctorId={doctor?._id}/></Td>
                      <Td className="" >
                        {formattedData}
                      </Td>
                      <Td><SubscriptionButton normalSubscriptionn={doctor?.subscription35} paymentStatus={doctor?.paymentStatus} 
                      premiumSubscriptionn={doctor?.subscription45} yearlySubscriptionn={doctor?.subscription150}
                      doctorId={doctor?._id}/> </Td>
                       
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
    </>
  )
}

export default DoctorTable